.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vw;
}
.header {
  min-height: 70px;
  max-height: 70px;
  flex: 1;
  background-color: black;
}
.header-dropdown {
  background-color: black;
}
.navbar-toggler {
  margin-right: 20px;
  border: 2px solid white;
}

.header-logo {
  height: 50px;
  padding-left: 10px;
  padding-right: 40px;
}
.navbar-collapse {
  justify-content: space-between;
  padding-right: 40px;
}
.nav-link {
  color: white;
}
.nav-link:hover {
  color: #00a0db;
}
.header-text {
  color: white
}
.account-icon {
  color: #00a0db;
}
.account-icon:hover {
  color: white;
}
.page {
  flex: 1;
  min-height: calc(100vh - 70px);
  background-color: #00a0db;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.page-card {
  background-color: white;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  margin: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  border-style: solid;
  border-width: 3px;
  border-color: black;
}
.page-card > * {
  margin: 10px;
}
.page-title {
  font-weight: 500;
  font-size: calc(2.3rem + 0.6vw);
  color: black;
}

.sign-in-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 7px;
}

.sign-in-form > * {
  margin: 2px;
}

.btn-primary {
  background-color: #00a0db;
  border-color: #00a0db;
}
.sign-in-button {
  margin-top: 10px;
}

.flex-align-center {
  align-self: center;
}
.flex-align-start {
  align-self: flex-start;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; 
}
.flex-centered {
  display: flex;
  align-items: center;
  justify-content: center; 
}
.text-bold {
  font-weight: bold;
}

.begoodpeople {
  font-size: 40px;
  color: black;
}

.info-list-item > .key {
  font-weight: bold;
  margin-right: 10px;
}
.session-list {
  display: flex;
  flex-direction: column;
}
.session-item {
  margin-top: 15px;
  border-top: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.session-item:first-child {
  margin-top: 0px;
  border-top: 0px solid black;
}
.error-text {
  color: red;
}
.chart-card { 
  background-color: white;
  justify-content: center;
  margin: 20px;
  padding-top: 5px;
  border-radius: 10px;
  border-style: solid;
  border-width: 3px;
  border-color: black;
  width: 95vw;
  max-width: 1000px;
}
.bar-chart {
  flex:1;
  height: 100%;
  width: 100%;
}
.selector {
  padding: 5px;
  margin: 10px;
}
.selector-dark {
  padding: 5px;
  margin: 10px;
  background-color: black;
  color: white;
  border-color: white;
  border-radius: 10px;
}
.category-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.category-title-buttons > *:last-child {
  margin-left: 8px;
  margin-right: 8px;
}
.category-title-button {
  margin-left: 2px;
}
.btn-secondary {
  font-size: 0.8em;
}
@media only screen and (max-width: 990px) {
  .header-dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .header-item-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media only screen and (min-width: 990px) {
  .header-dropdown {
    display: flex;
    flex-direction: row;
  }
  .header-item-list {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.table {
  width: 100%;
}
.big-button {
  width: 250px;
  font-size: 1.1em;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.category-title > * {
  margin: 0;
}
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg)}}
